<template>
  <div class="form-images">
    <div class="--label h4">
      {{ label }}
    </div>
    <div class="--items">
      <div
        v-for="(image, index) in images"
        class="--image"
      >
        <img :src="_URL.createObjectURL(image)"/>
        <div
          class="--remove"
          @click="() => onRemove(index)"
        />
      </div>
      <label class="--icon">
        <img src="~/assets/img/svg/common/image.svg"/>
        <input
          type="file"
          accept="image/jpeg,image/png"
          hidden
          multiple
          @change="onUpload"
        />
      </label>
      <div
        v-if="images.length <= 0"
        class="--message h5"
      >
        Нажмите на кнопку или перетащите фото в эту область до 10 изображений в формате PNG, JPEG.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

interface IProps {
  label: string;
  images: any[];
}

const props = withDefaults(defineProps<IProps>(), {
  label: 'Добавьте фотографии',
  images: () => []
});
const emits = defineEmits(['onChange']);
const _URL: any = window.URL;

const onRemove = (index: number) => {
  let _images = [...props.images];
  _images.splice(index, 1);
  emits('onChange', _images);
};
const onUpload = (event: any) => {
  const eventImages = [...(event?.target?.files || [])];
  const _images = [...props.images, ...eventImages];
  event.target.value = null;
  emits('onChange', _images);
}
</script>

<style scoped lang="scss">
.form-images {
  margin-top: 32px;

  .--label {
    color: black;
    margin-bottom: 16px;
  }

  .--items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    margin-top: -16px;
    margin-left: -16px;

    & > * {
      margin-top: 16px;
      margin-left: 16px;
    }
  }

  .--icon, .--image {
    width: calc(100% / 4 - 16px);
    max-width: 100px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;

    img, video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: "";
      float: left;
      padding-top: 100%;
    }
  }

  .--icon {
    background-color: #F4F3F1;
    cursor: pointer;

    img {
      width: 40px;
      filter: invert(60%) sepia(11%) saturate(9%) hue-rotate(111deg) brightness(99%) contrast(90%);
    }
  }

  .--image img,
  .--image video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .--remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 100%;
    background-image: url("@/assets/img/svg/cart/plus.svg");
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .--message {
    color: #989898;
    flex: 1;
  }
}
@media (max-width: 1023px) {
  .form-images {
    flex-direction: column;
    align-items: flex-start;

    .--label {
      margin-bottom: 14px;
      font-size: 16px;
    }
  }
  .form-images {
    margin-top: 17px;

    .--icon {
      width: 70px;
      height: 70px;
    }
    .--message {
      font-size: 12px;
    }
  }
}
</style>
